.request-project {
    &__buttons {
        display: flex;
        justify-content: space-between;

        @include until($tablet) {
            flex-flow: column-reverse nowrap;
        }
    }
}
