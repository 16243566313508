// TODO: We can add any top level helper class or function, but will most likely remove this file.

.react-tooltip {
    border-radius: 6px !important;
}

.tooltip {
    background-color: #656565;    
}

.tooltip--mousedown {
    background: #00a063 !important;
}
