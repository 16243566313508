.tech-stack {
    background-color: map-get($alt-basic, "strong");

    &__logo {
        width: 25%;
        border-right: 1px solid rgba(255, 255, 255, 0.25);
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        padding: 0 20px;
        min-height: 98px;

        @include until($tablet) {
            padding: 0 28px;
            width: 50%;
            float: left;
        }

        img {
            width: auto;
            max-height: 40px;
            height: 100%;
        }
    }

    // Each tech stack has bottom and right borders. Since we don't want surrounding borders,
    // we are using and absolute div to mask unwanted parts. (This comes very handy especially on mobile)
    // The reason for a large border value is to mask overflowing tech stack borders caused by p-0 class in the HTML.
    &__border-offset {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 15px solid map-get($alt-basic, "strong");
    }
}
