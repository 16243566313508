// $name is one of => magenta, blue, green, primary, secondary, tertiary, contrast, valid, success, warning, error, danger, invalid, info
// $weight is one of strong, medium, base, subtle
// e.g. has-background-blue-strong
@each $name, $palette in $bw-colors {
    $weights: "strong", "medium", "base", "subtle";
    @each $weight in $weights {
        $color: map-get($palette, $weight);

        .has-background-#{$name}-#{$weight} {
            background-color: $color;
        }

        .has-text-#{$name}-#{$weight} {
            color: $color;
        }
    }
}

// $brand-color is one of => red, green, blue
// e.g. has-background-brand-red
// e.g. has-text-brand-blue
@each $brand-color, $brand-color-value in $brand {
    .has-background-brand-#{$brand-color} {
        background-color: $brand-color-value;
    }

    .has-text-brand-#{$brand-color} {
        color: $brand-color-value;
    }
}

@for $i from 0 through 41 {
    $spacing: 4px * $i;
    .mb-custom-#{$i} {
        @include from($tablet) {
            margin-bottom: $spacing !important;
        }
    }

    .mb-custom-#{$i}-mobile {
        @include until($tablet) {
            margin-bottom: $spacing !important;
        }
    }
}

.has-background-gradient {
    background-image: linear-gradient(-170deg, rgba(128, 153, 255, 0.68) -51%, transparent 64%);
}

// .has-background-transparent {
//     background-color: transparent;
// }

// .has-background-true-black {
//     background-color: #000000;
// }

.has-background-gray {
    background-color: #1f1f1f;
}

// TODO: These styles will be moved to bw-css
.sd-title,
.sd-subtitle {
    color: inherit;
    letter-spacing: normal;

    &.is-1 {
        font-size: map-get(map-get($heading-sizes, size1), "desktop");
        line-height: 1.15;

        @include until($tablet) {
            font-size: map-get(map-get($heading-sizes, size1), "mobile");
        }
    }

    &.is-2 {
        font-size: map-get(map-get($heading-sizes, size2), "desktop");
        line-height: 1.125;

        @include until($tablet) {
            font-size: map-get(map-get($heading-sizes, size2), "mobile");
        }
    }

    &.is-3 {
        font-size: map-get(map-get($heading-sizes, size3), "desktop");
        line-height: 1.28;

        @include until($tablet) {
            font-size: map-get(map-get($heading-sizes, size3), "mobile");
        }
    }

    &.is-4 {
        font-size: map-get(map-get($heading-sizes, size4), "desktop");
        line-height: 1.3;

        @include until($tablet) {
            font-size: map-get(map-get($heading-sizes, size4), "mobile");
        }
    }

    &.is-5 {
        font-size: map-get(map-get($heading-sizes, size5), "desktop");
        line-height: 1.16;

        @include until($tablet) {
            font-size: map-get(map-get($heading-sizes, size5), "mobile");
        }
    }

    &.is-6 {
        font-size: map-get(map-get($heading-sizes, size6), "desktop");
        line-height: 1.18;

        @include until($tablet) {
            font-size: map-get(map-get($heading-sizes, size6), "mobile");
        }
    }
}
