// TODO revisit this as HTML doesn't have this class on the top level
.impact-panel {
    .accordion__item {
        &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        }
    }

    .accordion__button {
        display: flex;
        padding: 24px 0;

        &-icon {
            margin-right: 20px;
        }

        &-chevronWrapper {
            margin-left: 20px;
        }
    }

    .accordion__button[aria-expanded="true"]
        .accordion__button-chevronWrapper-chevron {
        transform: rotate(180deg);
    }

    .accordion__panel {
        padding-left: 60px;
    }

    .has-background-blue-steps {
        $step1-blue-color: map-get(map-get($fill-accent, "blue"), "strong");
        $step2-blue-color: map-get($brand, "blue");
        background-color: $step1-blue-color;
        background: linear-gradient(
            90deg,
            $step1-blue-color 0%,
            $step1-blue-color 66.6%,
            $step2-blue-color 66.6%,
            $step2-blue-color 100%
        );
    }

    .has-background-magenta-steps {
        $step1-magenta-color: map-get(map-get($fill-accent, "magenta"), "base");
        $step2-magenta-color: map-get(
            map-get($fill-accent, "magenta"),
            "medium"
        );
        background-color: $step1-magenta-color;
        background: linear-gradient(
            90deg,
            $step1-magenta-color 0%,
            $step1-magenta-color 66.6%,
            $step2-magenta-color 66.6%,
            $step2-magenta-color 100%
        );
    }
}
