.project-list {
    &__item {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        background-color: #2e3345;

        &:not(:last-child) {
            margin-bottom: 2px;
        }

        &:hover,
        &:active {
            background-color: #bfffe3;

            div {
                filter: brightness(0%);
            }
        }

        &--link {
            display: block;
            cursor: pointer;
            color: inherit;
            padding: 24px 36px;

            @include until($tablet) {
                padding: 24px;
            }
        }

        &--icon {
            width: 20px;
            height: 20px;
            margin-left: 12px;
        }
    }
}
