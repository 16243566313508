// TODO: We should move this in react-ui-components -> global theme
html {
    background: black none;
}

// TODO: We should move this in react-ui-components -> global theme
body {
    font-size: 16px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#root > :only-child {
    overflow: unset;
}

.columns {
    margin-top: 0;

    &:last-child {
        margin: {
            top: 0;
            bottom: 0;
        }
    }

    &:not(:last-child) {
        margin-bottom: 0;
    }
}

.column {
    padding: {
        top: 0;
        bottom: 0;
    }
}

.title:not(.is-spaced) + .subtitle,
.accordion-header-title:not(.is-spaced) + .subtitle {
    margin-top: 0;
}

.title:not(:last-child),
.accordion-header-title:not(:last-child),
.subtitle:not(:last-child) {
    margin-bottom: 0;
}

hr {
    height: 1px;
    margin: 0;
}

// TODO: Remove this block once these styles are managed in bw-react-ui-components lib!!
// input {
//     border-radius: 2px !important;

//     &:focus {
//         border-width: 2px !important;
//         padding: 0px 11px !important;
//     }
// }

// Change WaveLib z-index to avoid overlap
.wave-lib--user-details-popover {
    z-index: 40 !important;
}
