.cookie {
    &__container {
        bottom: 0px;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        left: 0px;
        position: fixed;
        width: 100%;
        z-index: 999;
        padding: 24px 40px;

        @include until($tablet) {
            padding: 32px 40px 24px;
        }
    }

    &__textWrapper {
        padding-right: 16px;

        a {
            color: white;
            text-decoration: underline;
        }

        @include until(1133px) {
            margin-bottom: 24px;
        }
    }

    &__buttonWrapper {
        @include until($tablet) {
            margin: 0 auto;
        }

        &--button {
            background-color: transparent;
            padding: 4px 8px;
            border-radius: 4px;
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
            border: 1px solid white;
            cursor: pointer;
            color: white;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }

            &:active {
                background-color: white;
                color: map-get(map-get($fill-accent, "blue"), "medium");
            }

            @include until($tablet) {
                padding: 12px 16px;
            }
        }
    }
}
