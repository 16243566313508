.who-we-are {
    padding-bottom: 96px;

    @include until($tablet) {
        padding-bottom: 0;
    }

    &__divider {
        background-color: map-get($fill-basic, "subtle");
    }

    &__card {
        padding-right: 48px;
        position: relative;

        @include until($tablet) {
            padding: 40px 48px;
            text-align: center;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 1px;
                width: 90%;
                border-top: 1px solid map-get($fill-basic, "subtle");
                margin: auto;
            }
        }
    }
}
