.the-community {
    background-image: url(https://static.breakingwave.com/images/background/Space_blue_dots.png),
        url(https://static.breakingwave.com/images/background/Space_red_dots.png);
    background-repeat: no-repeat;
    background-size: auto;

    @include until($tablet) {
        background-position: 100% 85%, 2% 97%;
    }

    @include from($tablet) {
        @include until($widescreen) {
            background-position: 300% 15%, -70% 110%;
        }
    }

    @include from($widescreen) {
        @include until($fullhd) {
            background-position: 850% 0%, -220% 105%;
        }
    }

    @include from($fullhd) {
        background-position: -1000% 15%, bottom right 5000%;
    }

    &__content {
        display: flex;

        @include until($tablet) {
            flex-direction: column-reverse;
        }
    }

    &__imagery {
        display: flex;
        margin-top: -48px;

        @include until($tablet) {
            margin-bottom: -36px;
        }

        img {
            margin-left: auto;

            @include until($tablet) {
                width: 227px;
            }
        }
    }
}
